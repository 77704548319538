<template>
  <div class="dialog">
    <div class="dialog-header">
      <div class="row">
        <div class="col">
          <p class="dialog-title text-uppercase mb-0">Update Profile</p>
        </div>
      </div>
    </div>
    <div class="dialog-content text-center"
         v-bind:style="{height: contentHeightOverride}"
         style="overflow-y: auto;">
      <p>Hello <b>{{ user.firstname }}</b>!</p>
      <p>We want to know more about you. <br>
        Are you a _____ ? <br>
        <span style="font-size: 0.8rem;">
          <i>Note: User type can only be updated once</i>
        </span>
      </p>

      <div class="row user-row">
        <div class="col user-col"
             v-for="(userType, index) in userTypes"
             :key="index">
          <img class="img-fluid user-type-img"
               :src="userType.img"
               :alt="userType.name"
               @click="setSelected(userType.value)"
               :class="userType.value === type ? 'active' : ''">
        </div>

        <div class="col-12 user-col pt-5 mb-3">
          <b-form-group class="text-left"
                        label="Set School"
                        v-if="type == 3">
            <school-search-select @changed="setSchool"
                                  :allowTag="true"/>
            <b-form-invalid-feedback id="school-select">
              <span v-if="!$v.schoolId.required">
                School is required.
              </span>
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group class="text-left"
                        label="Email"
                        v-if="!user.email && (type == 2 || type == 3)">
            <b-form-input type="email"
                          placeholder="Enter Email"
                          autocomplete="nope"
                          v-model="email"
                          @input="$v.email.$touch()"
                          :state="!$v.email.$error"
                          size="sm">
            </b-form-input>
            <b-form-invalid-feedback id="email-input">
              <span v-if="!$v.email.required">
                Email is required.
              </span>
              <span v-if="!$v.email.email && $v.email.required">
                This requires a valid email address.
              </span>
              <span v-if="!$v.email.uniqueEmail && $v.email.email &&
                      $v.email.required">
                Email is already registered.
              </span>
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
      </div>

      <div class="text-center">
        <b-button class="btn btn-success btn-h3-small"
                  type="button"
                  :disabled="type === null || $v.$invalid"
                  @click="updateUser">
          Update
        </b-button>
      </div>
    </div>

    <!-- Loading -->
    <loading :active.sync="isLoading"
             :is-full-page="true"
             background-color="#000"
             color="#068bcd"
             :opacity="0.5"
             :width="70"
             :height="70">
      <rainbow-loader></rainbow-loader>
    </loading>
  </div>
</template>

<script>
  import kidImg from '../../assets/images/kid_register_with_panda.png';
  import parentImg from '../../assets/images/parent_register_with_monkey.png';
  import educatorImg from '../../assets/images/educator_register_with_roy.png';
  import BreakpointMixin from '@/mixins/BreakpointMixin';
  import { mapActions, mapGetters } from 'vuex';
  import { required, email } from 'vuelidate/lib/validators';
  import 'vue-loading-overlay/dist/vue-loading.css';

  const Loading = () => import('vue-loading-overlay');
  const RainbowLoader = () => import('@/components/shared/RainbowLoader');
  const SchoolSearchSelect =
    () => import('@/components/shared/SchoolSearchSelect.vue');

  export default {
    name   : 'UpdateUserType',
    mixins : [
      BreakpointMixin,
    ],
    data() {
      return {
        type        : null,
        email       : null,
        schoolId    : null,
        timeout     : null,
        isLoading   : false,
        uniqueEmail : true,
      };
    },
    computed : {
      ...mapGetters({
        user         : 'user/user',
        subscription : 'subscription/subscription',
      }),
      userTypes() {
        let types = [];
        if (this.subscription.class_name !== 'free') {
          types = [
            {
              name  : 'Parent',
              img   : parentImg,
              value : 2,
            },
            {
              name  : 'Educator',
              img   : educatorImg,
              value : 3,
            },
          ];
        } else {
          types = [
            {
              name  : 'Kid',
              img   : kidImg,
              value : 1,
            },
            {
              name  : 'Parent',
              img   : parentImg,
              value : 2,
            },
            {
              name  : 'Educator',
              img   : educatorImg,
              value : 3,
            },
          ];
        }
        return types;
      },
    },
    components : {
      SchoolSearchSelect,
      RainbowLoader,
      Loading,
    },
    watch : {
      email(value) {
        this.uniqueEmail = true;
        if (value)
          this.checkEmail();
      },
    },
    methods : {
      ...mapActions({
        'getUpdatedUser' : 'user/getUpdatedUser',
      }),

      /**
       * Update User Type
       */
      updateUser() {
        this.isLoading = true;
        const fields = {
          // eslint-disable-next-line camelcase
          user_type : this.type,
        };

        if (this.type == 3 && this.schoolId === null) {
          this.$notify({
            group : 'notif',
            type  : 'info',
            title : 'Info',
            text  : 'School is required.',
          });
        } else {
          if (this.type == 3)
            // eslint-disable-next-line camelcase
            fields.school_id = this.schoolId;

          if ((this.type == 2 || this.type == 3) && this.user.email == '')
            fields.email = this.email;
            
          this.$http.put('api/user', {
            'userId'       : this.user.user_id,
            'updateFields' : fields,
          }).then(() => {
            this.getUpdatedUser();

            const vm = this;
            this.timeout = setTimeout(function () {
              vm.$notify({
                group : 'notif',
                type  : 'success',
                title : 'Success',
                text  : 'Updated Profile Successfully!',
              });
              vm.isLoading = false;
              vm.closeModal();
            }, 2000);
          }).catch(() => {
            this.$notify({
              group : 'notif',
              type  : 'failed',
              title : 'Failed',
              text  : 'Oops! Something went wrong.',
            });
            this.isLoading = false;
            this.closeModal();
          });
        }
      },

      /**
       * Close Modal
       */
      closeModal() {
        this.$emit('modalClose');
        this.$emit('close');
      },

      /**
       * Set Selected type
       * @param value
       */
      setSelected(value) {
        if (value) {
          this.type = value;
          this.$v.type.$touch();
        }
      },

      /**
       * Set Selected School
       * @param data
       */
      setSchool(data) {
        if (data) {
          this.schoolId = data.schoolId;
          this.$v.schoolId.$touch();
        }
      },

      /**
       * Check if Email is already registered
       */
      // eslint-disable-next-line no-undef
      checkEmail : _.debounce(function () {
        this.$http.get('api/user/email', {
          params : {
            email : this.email,
          },
        }).then(response => {
          // swapped value since api returns true
          //  if email exists then email is not unique
          this.uniqueEmail = (response.data) ? false : true;
        }).catch(() => {});
      }, 500),
    },
    validations() {
      return {
        type     : { required },
        schoolId : (this.type === 3) ? { required } : {},
        email    :
          (this.user.email == '' && (this.type === 3 || this.type === 2)) ? {
            email,
            required,
            uniqueEmail() {
              return this.uniqueEmail;
            },
          } : {},
      }
    },
  };
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/components/modals/update-user-types";
</style>
